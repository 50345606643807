const config = {
    "OPERATION_NAME":"lvmh shares",
    "API_URL":"https://api.lvmh-shares.ecollaboration-platform.com",
    "TINY_MCE_API_KEY":"1527x3ef5ajbywfdm25kadjmxzahw16uvrzdkfdn0sydl15y",
    "AUTH0_DOMAIN": "butterfly-henner.eu.auth0.com",
    "AUTH0_CLIENT_ID": "wbnIDJ5RC2JsXZxpLlo57hPtKUBMcFhz",
    "AUTH0_AUDIENCE": "https://api.lvmh-shares.ecollaboration-platform.com/",
};

export default config;
